import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {
  getActiveStudentThunk,
  getActiveStudentIncThunk,
  getTotalExamParticipationThunk,
  getCourseExamParticipationThunk,
  getNumTotalExParThunk,
  getTotalActThunk,
  getPassedFailedStuThunk,
  getCountriesCountThunk,
  getStudyPeriodsThunk,
} from './studyPeriodThunk'

import {formatNumber} from '../../utils/axios'

// Initial Data
const initialState = {
  studyPeriodNum: [],
  countryNum: 0,
  studyPeriodStartDate: 0,
  studyPeriodEndDate: 0,
  totalAverageGrade: 0,
  searchPeriod: 19,
  searchLang: 'ar',
  // selectedLanguage: 'ar',
  languageFilter: ['ar', 'en'],
  attemptsCount: 0,
  totalActiveStudent: 0,
  totalStudentLogin: 0,
  averageGrade: 0,
  averageParticipation: 0,
  // Active Student
  activeStudentData: {
    options: {
      chart: {
        id: 'active-student-chart',
        toolbar: {
          show: true,
        },
      },
      colors: ['#38ada9', '#C99B55'],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 7,
          columnWidth: '30%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: false,
        width: 0,
        colors: ['transparent'],
      },
      xaxis: {
        categories: [
          'Week 1',
          'Week 2',
          'Week 3',
          'Month 1',
          'Week 5',
          'Week 6',
          'Week 7',
          'Month 2',
          'Week 9',
          'Week 10',
          'Week 11',
          'Final',
          'Supplementary ',
        ],
      },
      responsive: [
        {
          breakpoint: 991,
          options: {
            plotOptions: {
              bar: {
                horizontal: true,
                borderRadius: 7,
                columnWidth: '30%',
                borderRadius: 0,
              },
            },
          },
        },
      ],
    },
    series: [
      {
        name: 'Active Students',
        data: [],
      },
      {
        name: 'Engaged Students',
        data: [],
      },
    ],
  },
  // Active Student Increase
  activeStudentDataInc: {
    options: {
      chart: {
        id: 'active-student-chart',
        toolbar: {
          show: true,
        },
      },
      colors: ['#C99B55', '#38ada9'],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '70%',
          endingShape: 'rounded',
          borderRadius: 5,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: false,
        width: 20,
        colors: ['transparent'],
      },
      xaxis: {
        categories: [
          'Week1',
          'Week2',
          'Week3',
          'Monthly1',
          'Week5',
          'Week6',
          'Week7',
          'Monthly2',
          'Week9',
          'Week10',
          'Week11',
          'Final',
          'Supplementary ',
        ],
      },
    },
    series: [
      {
        name: 'Active Total',
        data: [],
      },
      {
        name: 'Logins Total',
        data: [],
      },
    ],
  },
  // Total Exame Participation
  TotalExamParticipation: {
    series: [
      {
        name: 'Quiz Attempts',
        data: [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
      },
      colors: ['#C99B55'],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'smooth',
      },
      markers: {
        size: 1,
      },
      xaxis: {
        categories: [
          'Week 1',
          'Week 2',
          'Week 3',
          'Month 1',
          'Week 5',
          'Week 6',
          'Week 7',
          'Month 2',
          'Week 9',
          'Week 10',
          'Week 11',
          'Final',
          'Supplementary ',
        ],
      },
    },
  },
  //Passed Failed Exam
  PassedFailedStu: {
    series: [0, 0],
    options: {
      chart: {
        type: 'donut',
      },
      labels: ['Passed', 'Failed'],
      colors: ['#38ada9', '#ee5253'],
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          offsetY: 10,
        },
      },
      grid: {
        padding: {
          bottom: -80,
        },
      },
      dataLabels: {
        formatter(val, opts) {
          const name = opts.w.globals.labels[opts.seriesIndex]
          return [name, val.toFixed(1) + '%']
        },
      },
      legend: {
        show: false,
        position: 'left',
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 400,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  },
  // Course Exam Participation
  courseExamParticipation: {
    series: [
      {
        name: 'Exam Participations',
        type: 'area',
        data: [],
      },
      {
        name: 'Active Students',
        type: 'column',
        data: [],
      },
      {
        name: 'Average Grade',
        type: 'line',
        data: [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        stacked: false,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [2],
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '30%',
          endingShape: 'rounded',
          borderRadius: 5,
        },
      },
      colors: ['#C99B55', '#38ada9', '#7E8299'],
      stroke: {
        width: [1, 1, 4],
      },
      fill: {
        opacity: [0.5, 1, 0.5],
        gradient: {
          inverseColors: false,
          shade: 'light',
          type: 'vertical',
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      labels: [],
      xaxis: {
        categories: [
          'Week 1',
          'Week 2',
          'Week 3',
          'Month 1',
          'Week 5',
          'Week 6',
          'Week 7',
          'Month 2',
          'Week 9',
          'Week 10',
          'Week 11',
          'Final',
          'Supplementary ',
        ],
      },

      yaxis: [
        {
          seriesName: 'Attempts Count',
          opposite: false,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#C99B55',
          },
          labels: {
            show: true,
            style: {
              colors: '#C99B55',
            },
          },
          min: 0,
          max: 50000,
        },
        {
          seriesName: 'Users Count',
          opposite: false,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: true,
            color: '#38ada9',
          },
          labels: {
            style: {
              colors: '#38ada9',
            },
          },
          min: 0,
          max: 50000,
        },
        {
          seriesName: 'Average Grade',
          opposite: true,
          axisTicks: {
            show: true,
          },

          axisBorder: {
            show: true,
            color: '#FEB019',
          },
          labels: {
            style: {
              colors: '#FEB019',
            },
          },
        },
      ],
      tooltip: {
        fixed: {
          enabled: false,
          position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
          // offsetY: 30,
          // offsetX: 60,
        },
      },
    },
  },
}

// Active Student period
export const getStudyPeriods = createAsyncThunk(
  'activeStudent/getStudyPeriods',
  getStudyPeriodsThunk
)
// Active Student period
export const getCountriesCount = createAsyncThunk(
  'activeStudent/getCountriesCount',
  getCountriesCountThunk
)

// Active Student Chart
export const getActiveStudent = createAsyncThunk(
  'activeStudent/getActiveStudent',
  getActiveStudentThunk
)

// Active Total Student Chart
export const getTotalActiveStudent = createAsyncThunk(
  'activeStudent/getTotalActiveStudent',
  getTotalActThunk
)

// Active Student Increase Chart
export const getActiveStudentInc = createAsyncThunk(
  'activeStudent/getActiveStudentInc',
  getActiveStudentIncThunk
)

// Total Exame Participation
export const getTotalExamParticipation = createAsyncThunk(
  'activeStudent/getTotalExamParticipation',
  getTotalExamParticipationThunk
)

// Passed Failed Exam
export const getPassedFailedStu = createAsyncThunk(
  'activeStudent/getPassedFailedStu',
  getPassedFailedStuThunk
)

// Total Exam participation Number
export const getNumTotalExPar = createAsyncThunk(
  'activeStudent/getNumTotalExPar',
  getNumTotalExParThunk
)

// Course Exam Participation
export const getCourseExamParticipation = createAsyncThunk(
  'activeStudent/getCourseExamParticipation',
  getCourseExamParticipationThunk
)

const studyPeriodReducer = createSlice({
  name: 'studyPeriod',
  initialState,
  reducers: {
    handleChange: (state, {payload: {name, value}}) => {
      state.searchPeriod = value
      // console.log(state.searchPeriod)
      // state.searchLang = value
    },
    handleChangeLang: (state, {payload: {name, value}}) => {
      state.searchLang = value
      // console.log(state.searchPeriod)
      // state.searchLang = value
    },
    // changeSelectedLanguage: (state, {payload}) => {
    //   state.selectedLanguage = payload
    // },
  },
  extraReducers: (builder) => {
    builder

      // Study Period Number
      .addCase(getStudyPeriods.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getStudyPeriods.fulfilled, (state, {payload}) => {
        state.isLoading = false

        state.studyPeriodNum = payload.map((item) => item.number).slice(-3)
        // console.log(payload)

        const now = Math.floor(Date.now() / 1000)
        const validPeriods = payload.filter(item => item.starting_date <= now)
        const latestPeriod = validPeriods[validPeriods.length - 1]

        if (latestPeriod) {
          state.studyPeriodEndDate = latestPeriod.ending_date
          state.studyPeriodStartDate = latestPeriod.starting_date
          state.searchPeriod = latestPeriod.number
        } else {
          state.studyPeriodEndDate = 0
          state.studyPeriodStartDate = 0
          state.searchPeriod = 0
        }
      })
      .addCase(getStudyPeriods.rejected, (state, {payload}) => {
        state.isLoading = true
        console.log(payload)
      })

      //
      .addCase(getCountriesCount.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getCountriesCount.fulfilled, (state, {payload}) => {
        state.isLoading = false
        state.countryNum = payload[0]['COUNT(DISTINCT country)']
        // console.log(payload[0]['COUNT(DISTINCT country)'])
      })
      .addCase(getCountriesCount.rejected, (state, {payload}) => {
        state.isLoading = true
        console.log(payload)
      })

      // Active Student
      .addCase(getActiveStudent.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getActiveStudent.fulfilled, (state, {payload}) => {
        state.isLoading = false
        if (payload.length < 13) {
          for (let index = payload.length; index < 13; index++) {
            payload.push({})
          }
        }
        // state.activeStudentData.series[0].data = payload.map((item) => item.active_total)

        // state.activeStudentData.series[1].data = payload.map((item) => item.logins_total)

        state.activeStudentData.series[0].data = payload.map((item) =>
          formatNumber(item?.active_total || 0)
        )

        state.activeStudentData.series[1].data = payload.map((item) =>
          formatNumber(item?.enrolled_students_login || 0)
        )
      })
      .addCase(getActiveStudent.rejected, (state, {payload}) => {
        state.isLoading = true
        console.log(payload)
      })

      // Number of Total Active Students
      .addCase(getTotalActiveStudent.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getTotalActiveStudent.fulfilled, (state, {payload}) => {
        state.isLoading = false

        state.totalActiveStudent = payload[payload.length - 1].active_total
        state.totalStudentLogin = payload[payload.length - 1].logins_total
      })
      .addCase(getTotalActiveStudent.rejected, (state, {payload}) => {
        state.isLoading = true
        console.log(payload)
      })

      // Active Students Increase
      .addCase(getActiveStudentInc.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getActiveStudentInc.fulfilled, (state, {payload}) => {
        state.isLoading = false

        if (payload.length < 13) {
          for (let index = payload.length; index < 13; index++) {
            payload.push({})
          }
        }

        state.activeStudentDataInc.series[0].data = payload.map((item, index) => {
          if (index === 0) {
            return item.active_total
          } else {
            return item.active_total - payload[index - 1].active_total
          }
        })
        state.activeStudentDataInc.series[1].data = payload.map((item, index) => {
          if (index === 0) {
            return item.logins_total
          } else {
            return item.logins_total - payload[index - 1].logins_total
          }
        })
      })
      .addCase(getActiveStudentInc.rejected, (state, {payload}) => {
        state.isLoading = true
        console.log(payload)
      })

      // Total Exam Participation
      .addCase(getTotalExamParticipation.pending, (state) => {
        // state.isLoading = true
      })
      .addCase(getTotalExamParticipation.fulfilled, (state, {payload}) => {
        // state.isLoading = false
        if (payload.length < 13) {
          for (let index = payload.length; index < 13; index++) {
            payload.push({})
          }
        }

        state.TotalExamParticipation.options.xaxis.categories =
          state.TotalExamParticipation.options.xaxis.categories

        state.TotalExamParticipation.series[0].data = payload.map((item) =>
          isNaN(item.attempts_count) ? 0 : item.attempts_count
        )
      })
      .addCase(getTotalExamParticipation.rejected, (state, {payload}) => {
        // state.isLoading = true
        console.log(payload)
      })

      // Passed Failed Students
      .addCase(getPassedFailedStu.pending, (state) => {
        // state.isLoading = true
      })
      .addCase(getPassedFailedStu.fulfilled, (state, {payload}) => {
        // state.isLoading = false
        const passedCount = parseFloat(payload[0]?.passed_attempts_count)
        const failedCount = parseFloat(payload[0]?.failed_attempts_count)
        state.PassedFailedStu.series = [passedCount, failedCount]

        //
        state.totalAverageGrade = parseFloat(payload[0]?.average_grade).toFixed(2)
      })
      .addCase(getPassedFailedStu.rejected, (state) => {
        // state.isLoading = false
      })
      // Total Exam participation Number
      .addCase(getNumTotalExPar.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getNumTotalExPar.fulfilled, (state, {payload}) => {
        state.isLoading = false
        state.attemptsCount = payload[0].attempts_count
      })
      .addCase(getNumTotalExPar.rejected, (state, {payload}) => {
        state.isLoading = true
        console.log(payload)
      })

      // Course Exam Participation
      .addCase(getCourseExamParticipation.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getCourseExamParticipation.fulfilled, (state, {payload}) => {
        state.isLoading = false
        if (payload && payload.length < 13) {
          for (let index = payload.length; index < 13; index++) {
            payload.push({
              attempts_count: 0,
              average_duration: 0,
              average_grade: 0,
              exam_id: '',
              failed_attempts_count: 0,
              female_attempts_count: 0,
              female_students_count: 0,
              male_attempts_count: 0,
              male_students_count: 0,
              passed_attempts_count: 0,
              users_count: 0,
            })
          }
        }
        state.courseExamParticipation.series[0].data = payload.map((item) => item?.attempts_count || 0)
        state.courseExamParticipation.series[1].data = payload.map((item) => item?.users_count || 0)
        state.courseExamParticipation.series[2].data = payload.map((item) =>
          item?.average_grade ? item.average_grade.toFixed(0) : 0
        )

        state.courseExamParticipation.options.yaxis[0].max =
          Math.round(
            Math.max(
              ...payload.map((item) => item?.attempts_count || 0).filter((value) => !isNaN(value))
            ) / 1000
          ) * 1000 || undefined

        state.courseExamParticipation.options.yaxis[1].max =
          Math.round(
            Math.max(...payload.map((item) => item?.users_count || 0).filter((value) => !isNaN(value))) /
              1000
          ) * 1000 || undefined

        //Average Grade & Average Participation
        const gradesSum = payload.reduce(
          (sum, item) => sum + parseFloat(item?.average_grade || 0) * parseInt(item?.attempts_count || 0, 10),
          0
        )
        const sumAttempts = payload.reduce(
          (sum, item) => sum + parseInt(item?.attempts_count || 0, 10),
          0
        )

        state.averageGrade = (gradesSum / sumAttempts).toFixed(2)

        state.averageParticipation = (sumAttempts / payload.length).toFixed(2)
      })
      .addCase(getCourseExamParticipation.rejected, (state, {payload}) => {
        state.isLoading = true
        console.log(payload)
      })
  },
})

export const {handleChange, changeSelectedLanguage, handleChangeLang} = studyPeriodReducer.actions

export default studyPeriodReducer.reducer
