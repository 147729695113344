import {useSelector, useDispatch} from 'react-redux'
import {getStudyPeriods} from '../../data/StudyPeriod/studyPeriodSlice'
import {useEffect, useState} from 'react'
import moment from 'moment'

const StudyPeriodDate = () => {
  const {studyPeriodStartDate, studyPeriodEndDate, studyPeriodNum} = useSelector((state: any) => state.studyPeriod)

  const formattedStartDate = moment.unix(studyPeriodStartDate).format('YYYY-MMM-DD')
  const formattedEndDate = moment.unix(studyPeriodEndDate).format('YYYY-MMM-DD')

  const [progress, setProgress] = useState(0)

  const dispatch = useDispatch()

  useEffect(() => {
    const currentDate = moment()
    const totalDuration = moment(formattedEndDate).diff(moment(formattedStartDate))
    const elapsedDuration = currentDate.diff(moment(formattedStartDate))
    const percentage = (elapsedDuration / totalDuration) * 100

    setProgress(percentage > 100 ? 100 : percentage)

    dispatch(getStudyPeriods(formattedStartDate))
  }, [dispatch, formattedStartDate, formattedEndDate])

  return (
    <div className='study-period-date'>
      <h3 className='title'>{`${studyPeriodNum[studyPeriodNum.length - 1]}th Academy Study Period`}</h3>
      <div className='progress-container'>
        <div className='row'>
          <div className='col-4 col-lg-3'>
            <div className='box-date'>
              <i className='fa-regular fa-calendar-minus'></i>
              <span>{formattedStartDate}</span>
            </div>
          </div>
          <div className='col-4 col-lg-6 m-auto'>
            <div className='progress m-auto h-6px w-100'>
              <div
                className='progress-bar bg-success'
                role='progressbar'
                style={{width: `${progress}%`}}
              ></div>
            </div>
          </div>
          <div className='col-4 col-lg-3'>
            <div className='box-date'>
              <span>{formattedEndDate}</span>
              <i className='fa-regular fa-calendar-minus'></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StudyPeriodDate
